import * as React from 'react';
import { Link } from 'gatsby';

import * as styles from './GalleryImage.module.scss';

export type GalleryImageProps = {
  alt: string;
  url: string;
};

const GalleryImage = ({ alt, url }: GalleryImageProps) => (
  <div className={styles.wrapper}>
    <img className={styles.image} src={url} alt={alt} />
  </div>
);

export default GalleryImage;
