import * as React from 'react';
import { Link } from 'gatsby';

import placeholder from '../../images/placeholder.jpg';

import Gallery from '../../components/Gallery/Gallery';

const images = [
  {
    url: placeholder,
    alt: 'Placeholder',
  },
  {
    url: placeholder,
    alt: 'Placeholder',
  },
  {
    url: placeholder,
    alt: 'Placeholder',
  },
  {
    url: placeholder,
    alt: 'Placeholder',
  },
  {
    url: placeholder,
    alt: 'Placeholder',
  },
  {
    url: placeholder,
    alt: 'Placeholder',
  },
  {
    url: placeholder,
    alt: 'Placeholder',
  },
  {
    url: placeholder,
    alt: 'Placeholder',
  },
  {
    url: placeholder,
    alt: 'Placeholder',
  },
  {
    url: placeholder,
    alt: 'Placeholder',
  },
];

const GalleryContainer = ({ moreLink }) => (
  <Gallery images={images} moreLink={moreLink} />
);

export default GalleryContainer;
