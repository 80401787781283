import * as React from 'react';
import { Link } from 'gatsby';

import GalleryImage, {
  GalleryImageProps,
} from './../GalleryImage/GalleryImage';
import Title from '../Title/Title';

import * as styles from './Gallery.module.scss';

type GalleryProps = {
  images: Array<GalleryImageProps>;
  moreLink?: string;
};

const Gallery = ({ images, moreLink }: GalleryProps) => (
  <section className={styles.wrapper}>
    <div className={styles.container}>
      <Title text="Gallery" />
      <div className={styles.layout}>
        {images.map((image, index) => (
          <div className={styles.image} key={index}>
            <GalleryImage url={image.url} alt={image.alt} />
          </div>
        ))}
      </div>
      {moreLink ? (
        <p className={styles.moreLink}>
          <Link to={moreLink}>View more</Link>
        </p>
      ) : null}
    </div>
  </section>
);

export default Gallery;
