import * as React from 'react';
import { Link, graphql } from 'gatsby';

import PageLayout from '../components/PageLayout/PageLayout';
import GalleryContainer from '../containers/GalleryContainer/GalleryContainer';
import SEO from '../components/seo';

const Gallery = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`;
  const posts = data.donate.nodes;

  return (
    <PageLayout location={location} title={siteTitle}>
      <SEO title="Gallery" />
      <GalleryContainer moreLink="/gallery" />
    </PageLayout>
  );
};

export default Gallery;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    donate: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { section: { eq: "donate" } } }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          section
        }
      }
    }
  }
`;
